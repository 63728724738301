<script setup>
    const testimonials = await useStatamic().getEntries('testimonials')
</script>

<template>
    <Swiper
        :modules="[SwiperNavigation]"
        :loop="true"
        :autoplay="{
            delay: 8000,
            disableOnInteraction: true,
        }"
    >
        <SwiperNav />

        <SwiperSlide v-for="testimonial in testimonials" :key="testimonial.id" class="my-auto h-auto">
            <div class="mx-12 max-w-4xl select-none text-center md:mx-24 2xl:mx-auto">
                <q class="text-center text-xl text-brand1 sm:text-2xl md:text-3xl lg:text-4xl">
                    {{ testimonial.content }}
                </q>

                <figure v-if="testimonial.headshot">
                    <img
                        :src="testimonial.headshot.permalink.replace('uploads/', 'optimised/')"
                        :alt="testimonial.title"
                        class="mx-auto mt-6 h-16 w-16 rounded-full"
                    />
                </figure>

                <cite class="mt-6 block not-italic text-brand2">
                    {{ testimonial.title }}
                </cite>
            </div>
        </SwiperSlide>
    </Swiper>
</template>

<style scoped>
    q {
        quotes: '“' '”';
    }

    q:before,
    q:after {
        @apply text-brand2;
    }
</style>
