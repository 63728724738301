<script setup>
    const swiper = useSwiper()

    const buttonClasses =
        'flex items-center justify-center absolute w-10 h-10 top-1/2 transform -translate-y-1/2 rounded-full p-2 bg-brand2 icon icon-arrow-right after:h-[1em] after:aspect-square after:bg-white pointer-events-auto after:transition'
</script>

<template>
    <div class="absolute inset-y-0 z-10 w-full -translate-x-1/2 pointer-events-none 2xl:container left-1/2">
        <button
            @click="swiper.slidePrev()"
            class="swipe-prev after:rotate-180 md:hover:after:translate-x-1"
            :class="buttonClasses"
        />

        <button
            @click="swiper.slideNext()"
            class="swipe-next md:hover:after:-translate-x-1"
            :class="buttonClasses"
        />
    </div>
</template>

<style scoped>
    .swipe-prev {
        left: var(--padding);
    }

    .swipe-next {
        right: var(--padding);
    }
</style>
